import { TierFragment } from "graphql/fragments";
import {
  FeatureTypeEnum,
  PriceIntervalEnum,
  ProductTypeEnum,
  TierTypeEnum,
} from "graphql/types";
import { useTranslation } from "translations";
import {
  snakeCaseToCamelCase,
  snakeCaseToSeparated,
} from "utils/convertSnakeCase";
import { TTailwindString, tw } from "utils/tw";

import FeatureList from "./FeatureList";
import FeatureTableHeading from "./FeatureTableHeading";

interface Props {
  tiers: TierFragment[];
  currentTier?: TierFragment;
  onSelect?: (tier: TierFragment) => void;
}

export default ({ tiers, currentTier, onSelect }: Props): JSX.Element => {
  const { t } = useTranslation("login");

  const getListItemFromFeature = (feature: FeatureTypeEnum) => ({
    label: t(
      `planSelection.features.feature_${snakeCaseToCamelCase(feature)}`,
      snakeCaseToSeparated(feature)
    ),
    plans: [
      ...tiers.map(({ features }) => features.some((feat) => feat === feature)),
      true,
    ],
  });

  const productValues = {
    unlimited: t("planSelection.features.product.unlimited", "Unlimited"),
    custom: t("planSelection.features.product.custom", "Custom pricing"),
  };
  const getListItemFromProduct = (product: ProductTypeEnum) => ({
    label: t(
      `planSelection.features.product_${snakeCaseToCamelCase(product)}.label`,
      snakeCaseToSeparated(product)
    ),
    plans: [
      ...tiers.map(({ interval, products, features }) => {
        switch (product) {
          case ProductTypeEnum.ESignature:
            return products.eSignature.limit === 0
              ? t(
                  "planSelection.features.product_eSignature.valueWithoutLimit",
                  "{{ amount }} {{ currency }} per signature",
                  {
                    amount: products.eSignature.amount / 100,
                    currency: products.eSignature.currency,
                  }
                )
              : products.eSignature.limit
                ? t(
                    `planSelection.features.product_eSignature_${interval.toLowerCase()}.valueWithLimit`,
                    `{{ limit }} free per ${interval.toLowerCase()}\n{{ amount }} {{ currency }} per signature`,
                    {
                      limit: products.eSignature.limit,
                      amount: products.eSignature.amount / 100,
                      currency: products.eSignature.currency,
                    }
                  )
                : productValues.unlimited;

          case ProductTypeEnum.DocuCheck:
            return products.docuCheck.limit === 0
              ? t(
                  "planSelection.features.product_docuCheck.valueWithoutLimit",
                  "{{ amount }} {{ currency }} per check",
                  {
                    amount: products.docuCheck.amount / 100,
                    currency: products.docuCheck.currency,
                  }
                )
              : products.docuCheck.limit
                ? t(
                    `planSelection.features.product_docuCheck_${interval.toLowerCase()}.valueWithLimit`,
                    `{{ limit }} free per ${interval.toLowerCase()}\n{{ amount }} {{ currency }} per check`,
                    {
                      limit: products.docuCheck.limit,
                      amount: products.docuCheck.amount / 100,
                      currency: products.docuCheck.currency,
                    }
                  )
                : productValues.unlimited;

          case ProductTypeEnum.NordicIdCheck:
            return products.nordicIdCheck.limit === 0
              ? t(
                  "planSelection.features.product_nordicIdCheck.valueWithoutLimit",
                  "{{ amount }} {{ currency }} per check",
                  {
                    amount: products.nordicIdCheck.amount / 100,
                    currency: products.nordicIdCheck.currency,
                  }
                )
              : products.nordicIdCheck.limit
                ? t(
                    `planSelection.features.product_nordicIdCheck_${interval.toLowerCase()}.valueWithLimit`,
                    `{{ limit }} free per ${interval.toLowerCase()}\n{{ amount }} {{ currency }} per check`,
                    {
                      limit: products.nordicIdCheck.limit,
                      amount: products.nordicIdCheck.amount / 100,
                      currency: products.nordicIdCheck.currency,
                    }
                  )
                : productValues.unlimited;

          case ProductTypeEnum.NorskPolitiattest:
            return !features.includes(FeatureTypeEnum.CriminalRecordCheck)
              ? false
              : products.norskPolitiattest.limit === 0
                ? t(
                    "planSelection.features.product_norskPolitiattest.valueWithoutLimit",
                    "{{ amount }} {{ currency }} per check",
                    {
                      amount: products.norskPolitiattest.amount / 100,
                      currency: products.norskPolitiattest.currency,
                    }
                  )
                : products.norskPolitiattest.limit
                  ? t(
                      `planSelection.features.product_norskPolitiattest_${interval.toLowerCase()}.valueWithLimit`,
                      `{{ limit }} free per ${interval.toLowerCase()}\n{{ amount }} {{ currency }} per check`,
                      {
                        limit: products.norskPolitiattest.limit,
                        amount: products.norskPolitiattest.amount / 100,
                        currency: products.norskPolitiattest.currency,
                      }
                    )
                  : productValues.unlimited;

          case ProductTypeEnum.BasicCheck:
          case ProductTypeEnum.EnhancedCheck:
          case ProductTypeEnum.ComprehensiveCheck:
            return false;

          default:
            return ((_product: never) => "")(product);
        }
      }),
      [ProductTypeEnum.ESignature, ProductTypeEnum.DocuCheck].includes(product)
        ? productValues.unlimited
        : productValues.custom,
    ],
  });

  const systemFeatures = {
    icon: "HiSparkles" as const,
    heading: t("planSelection.features.system.heading", "System Features"),
    features: [
      FeatureTypeEnum.Encryption,
      FeatureTypeEnum.Wallet,
      FeatureTypeEnum.Modules,
      // FeatureTypeEnum.UnlimitedStorage,
      FeatureTypeEnum.AccessControl,
      FeatureTypeEnum.Import,
      // FeatureTypeEnum.DuplicateFilter,
      FeatureTypeEnum.Departments,
      FeatureTypeEnum.Archive,
      FeatureTypeEnum.Export,
      FeatureTypeEnum.OnboardingTraining,
      FeatureTypeEnum.KeyAccountManager,
      FeatureTypeEnum.CustomApiIntegrations,
      FeatureTypeEnum.CustomisedSolution,
      FeatureTypeEnum.CustomELearning,
    ].map(getListItemFromFeature),
  };

  const tellmoreFeatures = {
    icon: "HiSpeakerphone" as const,
    heading: t("planSelection.features.tellmore.heading", "Tellmore"),
    features: [
      FeatureTypeEnum.Whistleblowing,
      FeatureTypeEnum.Feedback,
      FeatureTypeEnum.FeedbackActivation,
      FeatureTypeEnum.QrCodes,
      // FeatureTypeEnum.AdvancedCaseManagement,
      FeatureTypeEnum.CustomBranding,
      FeatureTypeEnum.VoiceReporting,
    ].map(getListItemFromFeature),
  };

  const signmoreFeatures = {
    icon: "HiPencil" as const,
    heading: t("planSelection.features.signmore.heading", "Signmore"),
    features: [
      getListItemFromProduct(ProductTypeEnum.ESignature),
      getListItemFromFeature(FeatureTypeEnum.BulkSign),
    ],
  };

  const checkmoreFeatures = {
    icon: "HiFingerPrint" as const,
    heading: t("planSelection.features.checkmore.heading", "Checkmore"),
    features: [
      ProductTypeEnum.DocuCheck,
      ProductTypeEnum.NordicIdCheck,
      ProductTypeEnum.NorskPolitiattest,
    ].map(getListItemFromProduct),
  };

  const gridCols = `grid-cols-${tiers.length + 2}` as TTailwindString;

  return (
    <>
      {/* HEADINGS */}
      <div
        className={tw(
          "sticky",
          currentTier ? "top-0" : "top-16",
          "grid",
          gridCols,
          "bg-white"
        )}
      >
        <span />

        {tiers.map((tier) => (
          <FeatureTableHeading
            key={tier.id}
            billingCycle={
              tier.amount === 0
                ? t(
                    "planSelection.features.header.billingCycle.free",
                    "Upgrade whenever you want"
                  )
                : tier.interval === PriceIntervalEnum.Year
                  ? t(
                      "planSelection.features.header.billingCycle.yearly",
                      "Per month - billed annually"
                    )
                  : tier.interval === PriceIntervalEnum.Month
                    ? t(
                        `planSelection.features.header.billingCycle.monthly`,
                        "per month"
                      )
                    : undefined
            }
            tier={tier}
            currentTier={currentTier}
            onSelect={onSelect}
          />
        ))}

        <FeatureTableHeading
          tier={{
            id: "enterprise",
            amount: -1,
            currency: tiers[0].currency,
            interval: tiers[0].interval,
            type: TierTypeEnum.Enterprise,
            features: [],
            products: {} as TierFragment["products"],
          }}
        />
      </div>

      {/* CONTENT */}
      <FeatureList {...systemFeatures} gridCols={gridCols} />
      <FeatureList {...tellmoreFeatures} gridCols={gridCols} />
      <FeatureList {...signmoreFeatures} gridCols={gridCols} />
      <FeatureList {...checkmoreFeatures} gridCols={gridCols} />
    </>
  );
};
