import { lazy } from "react";

import {
  AccountInfo,
  AuthSettings,
  PageSelection,
  PaymentMethods,
  PaymentPlan,
  PayoutSettings,
  PolicyDocuments,
  Subscriptions,
  Team,
} from "./accountSettings";

const Dashboard = lazy(() => import("./Dashboard"));
const Overview = lazy(() => import("./Overview"));
const Person = lazy(() => import("./Person"));
const ScheduledDeletion = lazy(() => import("./ScheduledDeletion"));

export {
  AccountInfo,
  AuthSettings,
  Dashboard,
  PageSelection,
  PaymentMethods,
  PaymentPlan,
  PayoutSettings,
  Overview,
  Person,
  PolicyDocuments,
  Subscriptions,
  ScheduledDeletion,
  Team,
};
