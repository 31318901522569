import { useContext, useState } from "react";

import { Icon } from "components/common/basic";
import { IntlContext } from "providers/i18n";
import { useTranslation } from "translations";
import { getCountryNameFromCode, getNonSanctionedCountries } from "utils";

import MultiSearch from "./MultiSearch";

type CountryField = {
  label: string;
  value: string;
};

type Props = {
  id: string;
  errorMessage?: string;
  onSelect?: (countries: CountryField[]) => void;
};

export default ({ id, errorMessage, onSelect }: Props): JSX.Element => {
  const { currentLocale } = useContext(IntlContext);
  const { t } = useTranslation("checkmore");

  const countryCodes = getNonSanctionedCountries();
  const countryFields = countryCodes.map((countryCode) => ({
    label: getCountryNameFromCode(countryCode, currentLocale),
    value: countryCode,
  }));

  const [selectedCountries, setSelectedCountries] = useState<CountryField[]>(
    []
  );

  const handleSearch = (input: string): Promise<CountryField[]> => {
    return new Promise((resolve) => {
      const fields = input
        ? countryFields.filter((country) =>
            country.label.toLowerCase().includes(input.toLowerCase())
          )
        : countryFields;

      resolve(fields);
    });
  };

  const getButtonLabelText = (selectedCountries: CountryField[]) => {
    if (!selectedCountries.length) {
      return t("search_countriesMulti.button.label.empty", "Select");
    }

    if (selectedCountries.length > 1) {
      return t(
        "search_countriesMulti.button.label.filled",
        "{{ count }} selected",
        {
          count: selectedCountries.length,
        }
      );
    }

    return selectedCountries[0].label;
  };

  return (
    <MultiSearch
      id={id}
      label={t("search_countriesMulti.label", "Select the countries")}
      renderListItemLabel={(item) => <>{item.label}</>}
      buttonLabel={(isOpen) => (
        <>
          {getButtonLabelText(selectedCountries)}
          <Icon
            icon={isOpen ? "HiOutlineChevronUp" : "HiOutlineChevronDown"}
            size={20}
          />
        </>
      )}
      itemKey="value"
      selectedItems={selectedCountries}
      debounceTime={0}
      perPage={10}
      onSearch={handleSearch}
      onSelect={(countries) => {
        setSelectedCountries(countries);
        onSelect?.(countries);
      }}
      errorMessage={errorMessage}
    />
  );
};
