import { useContext, useEffect, useState } from "react";
// eslint-disable-next-line no-restricted-imports
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import Cookie from "js-cookie";

import { Spinner } from "components/common/basic";
import PayoutPartnerInfo from "components/common/pages/PayoutPartnerInfo";
import { AnalyticsContext } from "providers/Analytics";
import { AuthContext } from "providers/Authentication";
import { PopupContext } from "providers/PopupHandler";
import { IntlContext } from "providers/i18n";
import { NameSpace } from "translations/shared";
import { getParsedJSON, useRoutePaths } from "utils";
import { Module } from "utils";

interface Props {
  nameSpace: NameSpace;
}

export default ({ nameSpace }: Props): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const { i18n } = useTranslation();
  const { closeAllPopups } = useContext(PopupContext);
  const { currentLocale } = useContext(IntlContext);
  const { session } = useContext(AuthContext);
  const { reportEvent } = useContext(AnalyticsContext);

  const navigate = useNavigate();
  const getRoutePath = useRoutePaths();
  const { pathname } = useLocation();
  const { module } = useParams<{ module: Module }>();

  // Load translations for namespace
  useEffect(() => {
    const hasResourceBundle = i18n.hasResourceBundle(currentLocale, nameSpace);

    if (hasResourceBundle) setIsLoading(false);
    else i18n.loadNamespaces(nameSpace).then(() => setIsLoading(false));
  }, [nameSpace]);

  // Store last active account and module
  useEffect(() => {
    if (!session || !module) return;

    const ignoredModules: Module[] = ["test", "common", "login"];
    if (ignoredModules.includes(module)) return;

    const userId = session.user.id;
    const slug = session.account.slug;

    const cookie = Cookie.get("mm-module");
    const parsedCookie = getParsedJSON(cookie);
    const updatedValues = {
      ...(parsedCookie ?? {}),
      [userId]: { slug, module },
    };
    Cookie.set("mm-module", JSON.stringify(updatedValues));
  }, [session, pathname]);

  // On pathname change
  useEffect(() => {
    reportEvent("Displayed Route", { module, pathname });

    window.HubSpotConversations?.widget?.refresh();

    closeAllPopups();
  }, [pathname]);

  // Redirect from brand restricted routes
  useEffect(() => {
    const isManymore = process.env.REACT_APP_BRAND === "manymore";

    // Manymore routes
    const isManymoreSignupPath = [
      "TIER_SELECTION" as const,
      "SIGNUP_TEAM" as const,
    ].some((routeName) =>
      pathname.includes(
        getRoutePath({ module: "login", routeName }, { isGeneric: true })
      )
    );
    if (isManymoreSignupPath && !isManymore)
      return navigate(
        getRoutePath(
          { module: "login", routeName: "SIGNUP_PERSON" },
          { isGeneric: true }
        )
      );

    // PayoutPartner routes
    const isPayoutPartnerSignupPath = ["SIGNUP_PERSON" as const].some(
      (routeName) =>
        pathname.includes(
          getRoutePath({ module: "login", routeName }, { isGeneric: true })
        )
    );
    if (isPayoutPartnerSignupPath && isManymore)
      return navigate(
        getRoutePath(
          { module: "login", routeName: "TIER_SELECTION" },
          { isGeneric: true }
        )
      );
  }, [pathname]);

  if (isLoading) return <Spinner />;

  if (
    process.env.REACT_APP_BRAND === "manymore" &&
    module &&
    ["invoicemore", "payoutmore"].includes(module)
  )
    return <PayoutPartnerInfo />;

  return <Outlet />;
};
